<template>
  <modal
    transition="nice-modal-fade"
    :adaptive="true"
    :reset="true"
    width="800"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('addListing.video') }}
      </div>
      <div class="youtube--modal-wrapper">
        <iframe
          :src="youtubeEmbedUrl"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          loading="lazy"
        ></iframe>
      </div>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'youtube-modal',
  props: {
    youtubeUrl: {
      type: String,
      require: false,
    },
  },
  data() {
    return {
      modalName: 'youtube-modal',
      youtubeEmbedUrl: null,
    };
  },
  methods: {
    async beforeOpen() {
      this.youtubeEmbedUrl = await this.$store.dispatch(
        'v2/listing/getYoutubeEmbedUrl',
        this.youtubeUrl,
      );
    },
    close() {
      this.$modal.hide(this.modalName);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal--wrapper {
  padding: 0;
}
.close {
  display: none;
}
</style>
