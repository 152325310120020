<template>
  <section class="listing--detail-header">
    <div class="d-flex justify-space-between align-items-start">
      <h1 class="title--text">
        {{ listing.listing_title }}
        <!--        {{ listing.listing_title_mobile[0] }}-->
      </h1>
      <div class="button--wrapper">
        <div @click="toggleShortlist">
          <img
            v-if="shortlisted"
            src="@/assets/img/icons/favorite-filled.svg"
            alt="Favorite"
            class="favorite--filled"
          />
          <img v-else src="@/assets/img/icons/favorite.svg" alt="Favorite Outline" />
        </div>
        <share :url="''"></share>
      </div>
    </div>
    <div class="desc" v-if="location">
      <!--      <span>{{ listing.listing_title_mobile[1] }}</span>-->
      <span>{{ location }}</span>
    </div>
    <div class="label--wrapper">
      <!--      <div-->
      <!--        class="label label&#45;&#45;health"-->
      <!--        :class="`health&#45;&#45;${listing.covid_status.id}`"-->
      <!--        v-if="listing.covid_status"-->
      <!--        @click="showCovidProtocol()"-->
      <!--      >-->
      <!--        <img src="@/assets/img/icons/mask-2.svg" alt="Status Covid" />-->
      <!--        <span> {{ listing.covid_status.name }}</span>-->
      <!--      </div>-->
      <!--      <div class="label label&#45;&#45;normal">-->
      <!--        <img src="@/assets/img/icons/checkmark-1.svg" />-->
      <!--        <span>{{ $t('general.verified') }}</span>-->
      <!--      </div>-->
      <!--      <div class="label label&#45;&#45;normal">-->
      <!--        <img src="@/assets/img/icons/checkmark.svg" />-->
      <!--        <span>Properti terverifikasi</span>-->
      <!--      </div>-->
      <div class="label label--normal">
        <img src="@/assets/img/icons/eye.svg" alt="Jumlah Dilihat" />
        <span>{{ $t('general.view') }} {{ listing.view }} {{ $t('general.times') }}</span>
      </div>
    </div>
    <div class="info--wrapper">
      <div class="info" v-if="bedroom">
        <img src="@/assets/img/icons/bed.svg" alt="Kamar Tidur" />
        <span>
          {{ bedroom }}
          {{ $t('general.bedroom') }}
        </span>
      </div>
      <div class="info" v-if="bathroom">
        <img src="@/assets/img/icons/shower.svg" alt="Kamar Mandi" />
        <span>
          {{ bathroom }}
          {{ $t('general.bathroom') }}
        </span>
      </div>
      <div
        class="info"
        v-if="
          listing.property.property_type_id &&
          listing.property.property_type_id !== 12 &&
          listing.size_in_square_meters
        "
      >
        <img src="@/assets/img/icons/property-size.svg" alt="Luas Area" />
        <span>
          {{ listing.type === 'R' ? $t('addListing.areaSize') : $t('addListing.buildingSize') }}
          {{ listing.size_in_square_meters }} m<sup>2</sup>
        </span>
      </div>
      <div
        class="info"
        v-if="
          listing.property.property_type_id &&
          listing.property.property_type_id !== 12 &&
          listing.type === 'S' &&
          listing.land_size
        "
      >
        <img src="@/assets/img/icons/property-size.svg" alt="Luas Tanah" />
        <span>
          {{ $t('addListing.landSize') }}
          {{ listing.land_size }}
          m<sup>2</sup>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import Share from '@/components/utils/share';
import { mapState } from 'vuex';

export default {
  name: 'listing-detail-header',
  props: ['listing'],
  components: {
    Share,
  },
  computed: {
    ...mapState({
      shortlists: (state) => state.v2.search.shortlists,
      loggedIn: (state) => state.global.loggedIn,
    }),
    shortlisted() {
      if (this.shortlists && this.shortlists.length === 0) {
        return false;
      } else {
        return this.shortlists.indexOf(this.listing.id) !== -1;
      }
    },
    bedroom() {
      if (this.listing.facilities) {
        console.log('ss 1', this.listing.facilities);
        let bedroomTemp = this.listing.facilities.find((element) => element.attribute_id === 29);
        console.log('ss 2', bedroomTemp);
        if (bedroomTemp) {
          return bedroomTemp.amount;
        }
        return null;
      }
      return null;
    },
    bathroom() {
      if (this.listing.facilities) {
        console.log('ss 1', this.listing.facilities);
        let bedroomTemp = this.listing.facilities.find((element) => element.attribute_id === 10);
        console.log('ss 2', bedroomTemp);
        if (bedroomTemp) {
          return bedroomTemp.amount;
        }
        return null;
      }
      return null;
    },
    location() {
      if (this.listing && this.listing.property && this.listing.property.location) {
        let location = this.listing.property.location;
        return (
          location.village_name +
          ', ' +
          location.district_name +
          ', ' +
          location.city_name +
          ', ' +
          location.province_name +
          '.'
        );
      } else {
        return null;
      }
    },
  },
  methods: {
    showCovidProtocol() {
      this.$modal.show('modal-covid-protocol', {
        covidProtocol: this.listing.covid_protocol,
      });
    },
    async toggleShortlist() {
      if (this.loggedIn) {
        let response = await this.$store.dispatch(
          'v2/listing/addOrRemoveShortlist',
          this.listing.id,
        );
        await this.$store.commit('v2/search/set_shortlists', response);
      } else {
        this.$modal.show('modal-login');
      }
    },
  },
};
</script>
