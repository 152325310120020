<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    class="modal--installment"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('fintechInstallment.modalCicilan.header') }}
      </div>
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">
          {{ $t('fintechInstallment.modalCicilan.header') }}
        </div>
        <div class="row">
          <div class="col-6" v-if="cicilSewa" @click="setCompany('cicilsewa')">
            <v-card class="card--installment" :class="{ active: company === 'cicilsewa' }">
              <div class="subheader--row">
                <div class="left--col">
                  <img
                    src="https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/static/img/static/cicilsewa.png"
                    width="100"
                    alt="Cicilsewa Image"
                    style="padding: 8px"
                  />
                </div>
                <div class="right--col">
                  {{ $t('fintechInstallment.modalCicilan.subheaderCicilSewa') }}
                </div>
              </div>
              <!--              <div class="content&#45;&#45;row">-->
              <!--                <ul>-->
              <!--                  <li>-->
              <!--                    {{ `${$t('fintechInstallment.formCicilan.dp')}: ${cicilSewa.dp}` }}-->
              <!--                  </li>-->
              <!--                  <li>-->
              <!--                    {{-->
              <!--                      `${$t('fintechInstallment.formCicilan.rentDuration')}: ${-->
              <!--                        cicilSewa.rent_period-->
              <!--                      }`-->
              <!--                    }}-->
              <!--                  </li>-->
              <!--                  <li-->
              <!--                    v-for="(amount, index) in cicilSewa.installment_amount"-->
              <!--                    :key="`cicilsewa-amount-${index}`"-->
              <!--                  >-->
              <!--                    {{ amount }}-->
              <!--                  </li>-->
              <!--                </ul>-->
              <!--              </div>-->
            </v-card>
          </div>
          <div class="col-6" v-if="gradana" @click="setCompany('gradana')">
            <v-card class="card--installment" :class="{ active: company === 'gradana' }">
              <div class="subheader--row">
                <div class="left--col">
                  <img
                    src="https://rentfix.s3-ap-southeast-1.amazonaws.com/static/img/static/gradana.jpeg"
                    width="100"
                    alt="Gradana Image"
                  />
                </div>
                <div class="right--col">
                  {{ $t('fintechInstallment.modalCicilan.subheaderGradana') }}
                </div>
              </div>
              <!--              <div class="content&#45;&#45;row">-->
              <!--                <ul>-->
              <!--                  <li>-->
              <!--                    {{ gradana.dp }}-->
              <!--                  </li>-->
              <!--                  <li>-->
              <!--                    {{-->
              <!--                      `${$t('fintechInstallment.formCicilan.rentDuration')}: ${gradana.rent_period}`-->
              <!--                    }}-->
              <!--                  </li>-->
              <!--                  <li-->
              <!--                    v-for="(amount, index) in gradana.installment_amount"-->
              <!--                    :key="`gradana-amount-${index}`"-->
              <!--                  >-->
              <!--                    {{ amount }}-->
              <!--                  </li>-->
              <!--                </ul>-->
              <!--              </div>-->
            </v-card>
          </div>
        </div>
        <div class="question--row">
          {{ $t('fintechInstallment.modalCicilan.questionMessage') }}
        </div>
        <button class="btn btn-block btn--cta main--btn" @click="openForm" :disabled="!company">
          {{ $t('fintechInstallment.modalCicilan.requestBtn') }}
        </button>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'request-installment-modal',
  data() {
    return {
      modalName: 'modal-request-installment',
      listingUuid: '',
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.v2.fintechInstallment.loading,
      cicilSewa: (state) => state.v2.fintechInstallment.cicilSewa,
      gradana: (state) => state.v2.fintechInstallment.gradana,
    }),
    company: {
      get() {
        return this.$store.state.v2.fintechInstallment.company;
      },
      set(val) {
        this.$store.commit('v2/fintechInstallment/SET_COMPANY', val);
      },
    },
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      this.listingUuid = event.params.listingUuid;
      this.$store.dispatch('v2/fintechInstallment/getComparison', {
        listingUuid: this.listingUuid,
      });
    },
    async openForm() {
      this.$router.push({
        path: '/ajukan-cicilan',
        query: {
          listingUuid: this.listingUuid,
          company: this.company,
        },
      });
    },
    setCompany(type) {
      this.company = type;
    },
  },
};
</script>

<style scoped></style>
