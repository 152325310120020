<template>
  <section class="listing--detail-detail">
    <h2 class="title--text">
      {{ $t('addListing.propDetail') }}
    </h2>
    <div class="column--wrapper">
      <div class="column--content">
        <h3 class="column--title">
          {{ $t('general.propertyType') }}
        </h3>
        <div class="column--desc">
          {{ listing.property.property_type_name }}
        </div>
      </div>
      <div class="column--content" v-if="listing.unit_name">
        <h3 class="column--title">
          {{ $t('general.unitName') }}
        </h3>
        <div class="column--desc">
          {{ listing.unit_name }}
        </div>
      </div>
      <div class="column--content">
        <h3 class="column--title">
          {{ $t('general.totalFloor') }}
        </h3>
        <div class="column--desc">
          {{ listing.total_number_of_floor ? listing.total_number_of_floor : '-' }}
        </div>
      </div>
      <div class="column--content">
        <h3 class="column--title">
          {{ listing.type === 'R' ? $t('general.floorRent') : $t('general.floorSell') }}
        </h3>
        <div class="column--desc">
          {{ listing.floor ? listing.floor : '-' }}
        </div>
      </div>
      <div class="column--content" v-if="listing.type === 'R'">
        <h3 class="column--title">
          {{ $t('addListing.rentType') }}
        </h3>
        <div class="column--desc">
          {{ listing.rent_type.name }}
        </div>
      </div>
      <div class="column--content" v-if="listing.unit">
        <h3 class="column--title">
          {{ $t('general.unit') }}
        </h3>
        <div class="column--desc">
          {{ listing.unit }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'listing-detail-detail',
  props: ['listing'],
};
</script>
