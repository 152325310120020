<template>
  <modal
    :name="modalName"
    :width="600"
    :adaptive="true"
    :clickToClose="dismissable"
    height="auto"
    class="modal--input-phone"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('phoneForm.title') }}
      </div>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">
          {{ $t('phoneForm.title') }}
        </div>
        <!--        <v-card>-->
        <section-loading :show="loading" />
        <!--          <div class="wrapper&#45;&#45;title">-->
        <!--            <h4>{{ $t('phoneForm.subtitle') }}</h4>-->
        <!--            <hr />-->
        <!--          </div>-->
        <div role="alert" class="alert alert-warning mb0">
          {{ $t('phoneForm.message') }}
        </div>
        <div class="wrapper--input">
          <div :class="{ 'has-error': validation.hasError('phoneNumber') }" class="form-group">
            <!--            <label for="bankFormPhoneNumber">{{ $t('bankForm.phoneNumber') }}</label>-->
            <client-only>
              <vue-tel-input
                id="bankFormPhoneNumber"
                :value="phoneNumber ? phoneNumber : ''"
                @input="phoneInput"
                :validCharactersOnly="true"
                defaultCountry="id"
                :inputOptions="{ placeholder: $t('phoneForm.placeholder') }"
              />
            </client-only>
            <span class="val-error">{{ validation.firstError('phoneNumber') }}</span>
          </div>
          <div class="wrapper--button text-right">
            <button
              class="btn btn-primary d-block"
              style="width: 100%"
              id="submitPhone"
              @click="postData"
            >
              {{ $t('phoneForm.btn') }}
            </button>
          </div>
        </div>
        <!--        </v-card>-->
      </section>
    </div>
  </modal>
</template>

<script>
import { Validator } from 'simple-vue-validator';
// import Multiselect from 'vue-multiselect';
const SectionLoading = () => import('@/components/content-loading/section-loading');

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
export default {
  props: {
    showNotNowBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalName: 'modal-input-phone',
      loading: false,
      dismissable: false,
    };
  },
  computed: {
    phoneNumber() {
      return this.$store.state.v2.bankForm.phoneNumber;
    },
  },
  validators: {
    phoneNumber(value) {
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
  },
  components: {
    SectionLoading,
    VueTelInput,
  },
  methods: {
    async beforeOpen(event) {
      if (event.params && event.params.dismissable !== null) {
        this.dismissable = event.params.dismissable;
      }
    },
    phoneInput(phone, phoneObject) {
      if (phoneObject && phoneObject.countryCode) {
        this.phoneCountryCode = phoneObject.countryCode;
      } else {
        this.phoneCountryCode = null;
      }
      this.$store.commit('v2/bankForm/SET_PHONE_NUMBER', phone);
    },

    async postData() {
      try {
        this.loading = true;
        const isValid = await this.$validate();
        if (isValid) {
          await this.$http.post('/api/v2/profile/phone_number/update', {
            phone_number: this.phoneNumber,
            phone_country_code: this.phoneCountryCode,
          });
          // await this.$store.dispatch('v2/bankForm/postData');
          this.$swal(this.$t('general.success'), this.$t('profile.swal.phone.success'), 'success');
          this.$store.dispatch('global/fetch');
          this.postDone();
          // eslint-disable-next-line no-empty
        }
      } catch (e) {
        console.log('ERROR UPDATE PHONE NUMBER', e);
      } finally {
        this.loading = false;
      }
    },
    postDone() {
      this.$emit('postDone');
      this.$modal.hide(this.modalName);
    },
    close() {
      this.$emit('notNow');
      if (this.dismissable) {
        this.$modal.hide(this.modalName);
      } else {
        if (window.history.length > 1) {
          this.$router.back();
        } else {
          this.$router.replace('/');
        }
      }
    },
  },
};
</script>

<style></style>
