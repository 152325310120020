<template>
  <section
    class="listing--detail-contact"
    v-show="(isLoggedIn && listing.owner.id !== user.id) || !isLoggedIn"
  >
    <div class="contact--label">
      <img
        :src="listing.owner.photo_profile_user"
        width="50"
        height="50"
        :alt="listing.owner.fullname"
      />
      <router-link :to="`/user/listing/${listing.owner.uuid}`">
        <span>{{ listing.owner.fullname }}</span>
      </router-link>
    </div>
    <!--    <button v-if="!isLoggedIn" @click="handleContact" class="contact&#45;&#45;button">-->
    <!--      <img src="@/assets/img/icons/chat.svg" alt="Chat" />-->
    <!--      <span>{{ $t('general.contactOwner') }}</span>-->
    <!--    </button>-->
    <!--    <button-->
    <!--      v-else-if="listing.private_message === null"-->
    <!--      @click="handleContact"-->
    <!--      class="contact&#45;&#45;button"-->
    <!--    >-->
    <!--      <img src="@/assets/img/icons/chat.svg" alt="Chat" />-->
    <!--      <span>{{ $t('general.contactOwner') }}</span>-->
    <!--    </button>-->
    <!--    <router-link-->
    <!--      v-else-->
    <!--      :to="'/inbox/' + listing.private_message.uuid"-->
    <!--      class="btn btn-primary&#45;&#45;outline"-->
    <!--      >{{ $t('general.contactOwner') }}-->
    <!--    </router-link>-->
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'listing-detail-contact',
  props: ['listing'],
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.global.loggedIn,
      user: (state) => state.global.user,
    }),
  },
  methods: {
    handleContact() {
      if (!this.isLoggedIn) {
        this.$modal.show('modal-login');
      } else {
        this.$modal.show('modal-contact', { listing: this.listing });
      }
    },
  },
};
</script>
