<template>
  <div
    role="alert"
    class="alert alert-warning listing--detail-not-active mt-2"
    v-if="!listing.isActive"
  >
    <div>
      <span>{{ $t('general.inactiveListing1') }} </span>
      <a
        :href="`https://api.whatsapp.com/send?phone=${$t(
          'general.rentfixNumber',
        )}&text=${textMessage}`"
        >{{ $t('general.inactiveListing2') }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'listing-detail-not-active',
  props: ['listing'],
  computed: {
    textMessage() {
      return (
        encodeURIComponent(
          `Halo, saya ingin mencari properti yang serupa dengan properti ${this.listing.listing_title}`,
        ) + `%0a${this.listing.links ? this.listing.links.full_url : ''}`
      );
    },
  },
};
</script>
