<template>
  <section class="listing--detail-map">
    <h2 class="title--text">
      {{ $t('general.location') }}
    </h2>
    <div id="map" class="map--container"></div>
    <!--    <div class="desc">-->
    <!--      <img src="@/assets/img/icons/pin.svg" />-->
    <!--      <span>Centennial Tower Lt.35 Jl. Jend. Gatot Subroto Kav.24-25 Jakarta Selatan</span>-->
    <!--    </div>-->
  </section>
</template>

<script>
export default {
  name: 'listing-detail-map',
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  beforeDestroy() {
    this.map = '';
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap();
    });
  },
  data: () => ({
    map: '',
  }),
  methods: {
    initMap() {
      let self = this;
      let icon = {
        url: '/img/map_pin.png', // url
        // eslint-disable-next-line no-undef
        scaledSize: new google.maps.Size(50, 50), // scaled size
        // eslint-disable-next-line no-undef
        origin: new google.maps.Point(0, 0), // origin
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(25, 50), // anchor
      };
      const element = document.getElementById('map');
      const options = {
        center: {
          lat: self.property.latlng.lat,
          lng: self.property.latlng.lng,
        },
        mapType: 'hybrid',
        zoom: 16,
        maxZoom: 18,
        zoomControl: true,
        zoomControlOptions: {
          // eslint-disable-next-line no-undef
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        streetViewControl: true,
      };
      // eslint-disable-next-line no-undef
      self.map = new google.maps.Map(element, options);

      // eslint-disable-next-line no-undef
      new google.maps.Marker({
        // eslint-disable-next-line no-undef
        position: new google.maps.LatLng(self.property.latlng.lat, self.property.latlng.lng),
        icon: icon,
        map: self.map,
      });
    },
  },
};
</script>
