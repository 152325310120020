<template>
  <v-slide-group show-arrows class="carousel--wrapper listing--slick">
    <v-slide-item v-for="(content, index) in contents" :key="index" class="carousel--slide">
      <div>
        <card-project-partner
          v-if="category === 'projectpartner'"
          :projectPartner="content"
          :allowMultiImage="false"
        />
        <card-listing :premier="premier" v-else :listing="content" :allowMultiImage="false" />
      </div>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
const CardListing = () => import('@/components/utils/card-listing');
const CardProjectPartner = () => import('@/components/utils/card-project-partner');

export default {
  components: {
    CardProjectPartner,
    CardListing,
  },
  props: {
    contents: {
      default: [],
      require: true,
    },
    category: {
      default: null,
    },
    premier: {
      default: false,
    },
  },
  data: () => ({}),
  methods: {},
};
</script>
