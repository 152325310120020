<template>
  <section class="listing--detail-similar">
    <h2 class="title--text">
      {{ $t('general.similar') }}
    </h2>
    <div v-if="listListing && listListing.length > 0">
      <slick-listings :contents="listListing" />
    </div>
  </section>
</template>

<script>
import SlickListings from '@/components/home/partials/slick-listings';
export default {
  props: ['listing'],
  components: {
    SlickListings,
  },
  data() {
    return {
      listListing: [],
    };
  },
  methods: {
    async getSimilarListing() {
      try {
        this.listListing = await this.$store.dispatch('v2/search/getSimilar', {
          uuid: this.listing.uuid,
          listingType: this.listing.type,
        });
      } catch (e) {
        console.log('ERROR GETTING SIMILAR LISTING: ', e);
      }
    },
  },
  mounted() {
    this.getSimilarListing();
  },
};
</script>
