<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    class="modal--contact"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ title }}
      </div>
      <section-loader :show="loading" />
      <section class="modal--container">
        <form @submit.prevent="submit">
          <div class="title--text" v-if="$mq !== 'xs'">{{ title }}</div>
          <div class="form--group" :class="{ 'has-error': validation.hasError('message') }">
            <div style="width: 100%">
              <textarea
                name="message"
                id="contact-message"
                v-model="message"
                class="basic--input"
                :placeholder="$t('inbox.placeholder')"
                :class="{
                  'is-invalid': validation.firstError('message'),
                }"
                rows="5"
              ></textarea>
              <span class="val-error">{{ validation.firstError('message') }}</span>
            </div>
          </div>
          <button type="submit" class="btn btn-primary--outline">
            <img src="@/assets/img/icons/chat.svg" />
            <span>{{ title }}</span>
          </button>
        </form>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import { Validator } from 'simple-vue-validator';
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  name: 'contact-modal',
  components: { SectionLoader },
  data() {
    return {
      modalName: 'modal-contact',
      message: '',
      uuid: '',
      loading: false,
      title: '',
    };
  },
  validators: {
    message(value) {
      if (value === undefined) value = null;
      return Validator.value(value).required(this.$i18n.t('errors.contact.required'));
    },
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      if (event.params.listing) {
        this.uuid = event.params.listing.uuid;
      } else {
        this.uuid = event.params.listingUuid;
      }
      if (event.params.toOwner === undefined || event.params.toOwner === null) {
        this.title = this.$t('general.contactOwner');
      } else {
        if (event.params.toOwner) {
          this.title = this.$t('general.contactOwner');
        } else {
          if (event.params.listingType === 'R') {
            this.title = this.$t('general.contactApplicant');
          } else if (event.params.listingType === 'S') {
            this.title = this.$t('general.contactBuyer');
          }
        }
      }
      if (event.params.applicantId) {
        this.applicantId = event.params.applicantId;
      }
    },
    async submit() {
      try {
        this.loading = true;
        const isValid = await this.$validate();
        if (isValid) {
          try {
            let data = await this.$store.dispatch('v2/myinbox/contactHost', {
              uuid: this.uuid,
              message: this.message,
              applicantId: this.applicantId,
            });
            if (data.status === 'success') {
              this.close();
              this.$router.push('/inbox/' + data.result.message_thread.uuid);
            }
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
