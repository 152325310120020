<template>
  <section class="listing--detail-action">
    <div v-if="listing.type === 'R'">
      <div class="price--container">
        <div class="left--col">
          {{ $t('addListing.rentPrice') }}
        </div>
        <div class="right--col">
          <div class="price--item" v-for="rent_price in listing.rent_prices" :key="rent_price.id">
            <div class="price--before" v-if="listing.discount_percentage || listing.discount_price">
              {{ $n(rent_price.price, 'currency', 'id-ID') }} /{{ rent_price.periode }}
            </div>
            <div class="price--after">
              {{
                rent_price.price
                  ? $n(
                      getPriceAfterDiscount(
                        listing.discount_percentage,
                        listing.discount_price,
                        rent_price.price,
                      ).price_after_discount,
                      'currency',
                      'id-ID',
                    )
                  : 'Rp 0'
              }}
              /{{ rent_price.periode }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="price--container">
        <div class="left--col">
          {{ $t('addListing.sellPrice') }}
        </div>
        <div class="right--col">
          <div class="price--item">
            <div class="price--before" v-if="listing.discount_percentage || listing.discount_price">
              {{ $n(listing.sell_price, 'currency', 'id-ID') }}
            </div>
            <div class="price--after">
              {{
                listing.sell_price
                  ? $n(
                      getPriceAfterDiscount(
                        listing.discount_percentage,
                        listing.discount_price,
                        listing.sell_price,
                      ).price_after_discount,
                      'currency',
                      'id-ID',
                    )
                  : 'Rp 0'
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="price--container" v-if="!listing.free_reservation_fee">
        <div class="left--col">
          {{ $t('general.reservationFee') }}
        </div>
        <div class="right--col price--simple">
          {{ $n(500000, 'currency', 'id-ID') }}
        </div>
      </div>
      <div class="price--container" v-if="listing.done_year_est">
        <div class="left--col">
          {{ listing.is_done ? $t('general.builtAt') : $t('general.estimatedCompletionYear') }}
        </div>
        <div class="right--col price--simple">
          {{ listing.done_year_est }}
        </div>
      </div>
      <div class="price--container" v-else>
        <div class="left--col">
          {{ $t('general.status') }}
        </div>
        <div class="right--col price--simple">
          {{ $t('general.ready') }}
        </div>
      </div>
    </div>
    <div v-if="!isOwner">
      <not-active :listing="listing"></not-active>
      <div v-if="listing.isActive">
        <div>
          <div v-if="listing && listing.type === 'R'">
            <div class="btn btn-primary action--button" @click="requestToBookHandler">
              {{ $t('general.bookSpace') }}
            </div>
            <div class="installment--section" v-if="listing.cicilan_gradana">
              <div class="text-center mb-3">{{ $t('general.or') }}</div>
              <div class="btn btn--cta action--button" @click="requestInstallmentHandler">
                {{ $t('general.installmentRequest') }}
              </div>
              <!--            <span>{{ $t('general.installmentStarts') }} </span>-->
              <!--            <span>{{ $n(listing.cicilan_gradana.oneYearInstallment, 'currency', 'id-ID') }}.</span>-->
              <hr class="separator" />
            </div>
          </div>
          <reserve-listing
            v-else-if="listing && listing.type === 'S'"
            :full="true"
            :listing="listing"
          />
        </div>
        <div class="text-center mb-2">
          {{ $t('general.contactUsQuestion') }}
        </div>
        <a
          :href="`https://api.whatsapp.com/send?phone=${$t(
            'general.rentfixNumber',
          )}&text=${textMessage}`"
          class="btn btn--whatsapp action--button"
          style="text-decoration: none"
        >
          <img src="@/assets/img/icons/whatsapp-1.svg" alt="Whatsapp" />
          <span>{{ $t('general.contactUs') }}</span>
        </a>
        <div class="terms">
          {{ $t('general.tos1') }}
          <a href="/term-of-service" target="_blank">{{ $t('general.tos2') }}</a>
          {{ $t('general.and') }}
          <a href="/privacy-policy" target="_blank">{{ $t('general.tos3') }}</a
          >.
        </div>
      </div>
    </div>
    <owner-section v-if="loggedIn && isOwner" class="owner--section" :listing="listing" />
  </section>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

const ReserveListing = () => import('@/components/listing-detail/reserve-listing');
const OwnerSection = () => import('@/components/listing-detail/owner-section');
const NotActive = () => import('@/components/listing-detail/not-active');

export default {
  name: 'listing-detail-action',
  props: ['listing'],
  mixins: [HelperMixin],
  components: { ReserveListing, OwnerSection, NotActive },
  computed: {
    ...mapState({
      loggedIn: (state) => state.global.loggedIn,
    }),
    textMessage() {
      return (
        encodeURIComponent(
          `Halo, saya ingin bertanya mengenai properti ${this.listing.listing_title}`,
        ) + `%0a${this.listing.links ? this.listing.links.full_url : ''}`
      );
    },
    isOwner() {
      if (this.$store.state.global.user != null && this.listing.owner != null) {
        return this.$store.state.global.user.id == this.listing.owner.id;
      }
      return false;
    },
  },
  methods: {
    showModal(modalName) {
      this.$modal.show(modalName);
    },
    async openCtaSell() {
      let allowBook = true;
      let warningBook = '';
      if (this.loggedIn) {
        const result = await this.checkUnpaidBooking();
        allowBook = result.is_allowed;
        warningBook = result.warning_message;
        if (allowBook === true) {
          this.$router.push({
            path: '/transaction/checkout',
            query: { listing: this.listing.uuid },
          });
        } else {
          this.$modal.show('modal--check-booking', {
            message: warningBook,
          });
        }
      } else {
        this.$modal.show('modal--login');
      }
    },
    requestInstallmentHandler() {
      // if (this.loggedIn) {
      //   this.$modal.show('modal-request-installment', {
      //     listingUuid: this.listing.uuid,
      //     installmentGradana: this.listing.cicilan_gradana,
      //   });
      // } else {
      //   this.showModal('modal-login');
      // }
      this.$router.push({
        path: '/ajukan-cicilan',
        query: {
          listingUuid: this.listing.uuid,
          company: 'rentfix',
        },
      });
    },
    requestToBookHandler() {
      if (this.loggedIn) {
        this.showModal('modal-request-to-book');
      } else {
        this.showModal('modal-login');
      }
    },
  },
};
</script>
