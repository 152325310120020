<template>
  <section
    v-if="listing.listing_operationals && listing.listing_operationals.length !== 0"
    class="listing--detail-operational-hour"
  >
    <div class="title--text">
      {{ $t('addListing.operational.header') }}
    </div>
    <div class="table" v-if="$mq !== 'xs'">
      <div class="column">
        <div class="cell"></div>
        <div class="cell" v-for="(y, k) in dayInNumber" :key="k">
          <strong>{{ $t('date.day.' + y) }}</strong>
        </div>
      </div>
      <div class="column mb-2">
        <div class="cell">
          <strong>{{ $t('addListing.operational.from') }}</strong>
        </div>
        <div class="cell" v-for="(lo, k) in listing.listing_operationals" :key="k">
          <span v-if="lo.start_time !== null">{{ lo.start_time }}</span>
          <span
            class="badge badge-danger"
            v-if="lo.start_time === null"
            v-html="$t('addListing.operational.closed')"
          ></span>
        </div>
      </div>
      <div class="column">
        <div class="cell">
          <strong>{{ $t('addListing.operational.to') }}</strong>
        </div>
        <div class="cell" v-for="(lo, k) in listing.listing_operationals" :key="k">
          <span v-if="lo.end_time !== null">{{ lo.end_time }}</span>
          <span
            class="badge badge-danger"
            v-if="lo.end_time === null"
            v-html="$t('addListing.operational.closed')"
          ></span>
        </div>
      </div>
    </div>
    <div class="table" v-if="$mq === 'xs'">
      <div class="row">
        <div class="col-3">
          <p v-for="(y, k) in dayInNumber" :key="k">{{ $t('date.day.' + y) }}</p>
        </div>
        <div class="col-9 text-right">
          <p v-for="(lo, k) in listing.listing_operationals" :key="k">
            <span v-if="lo.start_time !== null">{{ lo.start_time }} - </span>
            <span v-if="lo.end_time !== null">{{ lo.end_time }} </span>
            <span
              class="text-center badge badge-danger"
              v-if="lo.end_time === null && lo.start_time === null"
              v-html="$t('addListing.operational.closed')"
            ></span>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'listing-detail-operational-hour',
  props: ['listing'],
  computed: {
    ...mapState({
      dayInNumber: (state) => state.v2.listing.dayInNumber,
    }),
  },
};
</script>
